document.addEventListener('DOMContentLoaded', (event) => {
    (function() {
        // Set default values for difficulty and game mode if they don't exist in local storage
        localStorage.getItem('difficulty') || localStorage.setItem('difficulty', 'easy');
        localStorage.getItem('gameMode') || localStorage.setItem('gameMode', 'one-player');

        // Function to toggle visibility of an element
        const toggleVisibility = (elementId, hideMenu = false) => {
            const element = document.getElementById(elementId);
            if (element) {
                element.classList.toggle('show');
                if (hideMenu) {
                    const menu = document.getElementById('menu');
                    menu.classList.remove('show');
                }
            }
        };

        // Function to set checked state of a radio button group
        const setCheckedState = (name, value) => {
            // Remove "(Current)" from all labels
            const labels = document.querySelectorAll('label');
            labels.forEach(label => {
                label.textContent = label.textContent.replace(' (Current)', '');
            });

            const radioButton = document.querySelector(`input[name="${name}"][value="${value}"]`);
            if (radioButton) {
                radioButton.checked = true;
                const label = document.querySelector(`label[for="${radioButton.id}"]`);
                if (label) {
                    label.textContent += ' (Current)';
                }
            }
        };

        // Function to set a value in local storage and update the checked state of a radio button group
        const setValue = (name, value) => {
            localStorage.setItem(name, value);
            setCheckedState(name, value);
        };

        document.getElementById('bot-difficulty-button').addEventListener('click', () => {
            toggleVisibility('difficulty-modal', true);
            setCheckedState('difficulty', localStorage.getItem('difficulty'));
        });
        // theme-button
        document.getElementById('theme-button').addEventListener('click', () => {
            toggleVisibility('theme-modal', true);
            setCheckedState('theme', localStorage.getItem('theme'));
        });

        document.getElementById('set-difficulty').addEventListener('click', () => {
            const difficulty = document.querySelector('input[name="difficulty"]:checked').value;
            setValue('difficulty', difficulty);
        });
        // document.getElementById('game-mode-button').addEventListener('click', () => {
        //     toggleVisibility('game-mode-modal');
        // });

        // Add event listeners for game mode radio buttons
        // document.getElementById('one-player').addEventListener('click', () => {
        //     setValue('gameMode', 'one-player');
        // });
        // document.getElementById('two-players').addEventListener('click', () => {
        //     setValue('gameMode', 'two-players');
        // });

        // Add event listener for "Set Game Mode" button
        document.getElementById('set-game-mode').addEventListener('click', () => {
            // Close the game mode modal
            toggleVisibility('game-mode-modal');
        });

        document.getElementById('menu-icon').addEventListener('click', () => {
            // If another menu is open, close it
            const openMenu = document.querySelector('.show');
            if (openMenu && openMenu.id !== 'menu') {
                openMenu.classList.remove('show');
            }
        
            // Toggle the visibility of the hamburger menu
            toggleVisibility('menu');
        });
        document.querySelectorAll('.close-button').forEach(button => {
            button.addEventListener('click', (event) => {
                const closeElementId = event.target.getAttribute('close-element-id');
                toggleVisibility(closeElementId);
            });
        });
        // Add click event listener to the game board
        document.getElementById('board').addEventListener('click', () => {
            // If a menu is open, close it
            const openMenu = document.querySelector('.show');
            if (openMenu) {
                openMenu.classList.remove('show');
            }
        });

        // Set initial checked states
        setCheckedState('difficulty', localStorage.getItem('difficulty'));
        setCheckedState('gameMode', localStorage.getItem('gameMode'));

        // Theme change functionality
        document.getElementById('set-theme').addEventListener('click', () => {
            const selectedTheme = document.querySelector('input[name="theme"]:checked').value;
            document.body.classList.remove('light', 'dark'); // Remove existing theme classes
            document.body.classList.add(selectedTheme); // Add the selected theme class
            localStorage.setItem('theme', selectedTheme); // Save the selected theme
        });

        // Apply the saved theme on page load
        // window.onload = function() {
        //     const savedTheme = localStorage.getItem('theme') || 'light'; // Default to light theme
        //     document.body.classList.add(savedTheme); // Apply the saved theme
        //     const themeRadioButton = document.getElementById(savedTheme + '-theme');
        //     if (themeRadioButton) {
        //         themeRadioButton.checked = true; // Check the saved theme's radio button
        //     }
        // };
    })();
});